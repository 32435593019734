import styled from 'styled-components';
import {
  UserContainer,
  UserBackdrop,
  UserPostTextArea,
} from '../../../components/Styles/User.styles';

export const Container = styled(UserContainer)``;

export const ContentArea = styled(UserPostTextArea)``;

export const Backdrop = styled(UserBackdrop)``;

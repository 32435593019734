import styled from 'styled-components';
import {
  AdminContainer,
  AdminContentArea,
  AdminButtonArea,
  AdminTitle,
  AdminSpanText,
} from '../../../components/Styles/Admin.styles';

export const Container = styled(AdminContainer)``;

export const ContentArea = styled(AdminContentArea)``;

export const ButtonArea = styled(AdminButtonArea)``;

export const Title = styled(AdminTitle)`
  margin-top: 62px;
`;

export const SpanText = styled(AdminSpanText)``;
